import { createRouter, createWebHistory } from 'vue-router';
import BookEventConfirmation from '../components/BookEventConfirmation.vue'; // Adjust the path as necessary

const routes = [
  // Define your routes here
  // Example route:
  // { path: '/', component: Home },
  { path: '/bookEvent', component: () => import('../components/BookEvent.vue') },
  { path: '/confirmation', component: BookEventConfirmation },
{ path: '/eventslist', component: () => import('../components/EventsList.vue') },
{ path: '/admin', name: 'Admin',component: () => import('../components/Admin.vue') },
{ path: '/spotifyAuthenticationComplete', component: () => import('../components/SpotifyAuthenticationComplete.vue') },
{ path: '/requestTrackConfirmation', name: 'RequestTrackConfirmation', component: () => import('../components/RequestTrackConfirmation.vue') },
{ path: '/managePlaylist', name: 'ManagePlaylist',component: () => import('../components/ManagePlaylist.vue') },
{ path: '/requestTrack', name: 'RequestTrack', component: () => import('../components/RequestTrack.vue') },
{ path: '/', name: 'MarketingHome', component: () => import('../components/MarketingHome.vue') },
{ path: '/about', name: 'About', component: () => import('../components/About') },
{ path: '/completedGoogleSignIn', name: 'CompletedGoogleSignIn', component: () => import('../components/CompletedGoogleSignIn.vue') },
{ path: '/manageThemes', name: 'ManageThemes', component: () => import('../components/ManageThemes.vue') },
{ path: '/helloworld', name: 'HelloWorld', component: () => import('../components/HelloWorld.vue') },
{ path: '/completedBooking', name: 'CompletedBooking', component: () => import('../components/CompletedBooking.vue') },
{ path: '/hostAdmin', name: 'HostAdmin', component: () => import('../components/HostAdmin.vue') },
{ path: '/hostAdmin2', name: 'HostAdmin2', component: () => import('../components/HostAdmin2.vue') },
{ path: '/whosRequested', name: 'WhosRequested', component: () => import('../components/WhosRequested.vue') },
{ path: '/draggableTable', name: 'DraggableTable', component: () => import('../components/DraggableTable.vue') },
{ path: '/themes', name: 'Themes', component: () => import('../components/Themes.vue') },
{ path: '/logins', name: 'Logins', component: () => import('../components/Logins.vue') },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;