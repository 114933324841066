export const createPlaylistForEvent = async (event) => {
  console.log("Creating Playlist")
  if (event.playlistID) {
    console.log(`Playlist ID already exists: ${event.playlistID}`);
    return { success: false, message: "Playlist ID already exists" };
  }
  // Your logic to create a playlist if playlistID doesn't exist goes here
 
  var playlistName = event.name

  if (!playlistName){
    console.log("event doesn't have a name");  
  }

  var response = await createSpotifyPlaylist(playlistName, "A playlist for the Living Room Disco event: "+ playlistName)
  console.log("playlist data " ,  response)

  if(response.success == true){
      event.playlistID = response.playlistID;
  }
  else  
  {
    event.playlistID = null
  }
}

export const createEvent = async () => {
  
}


export const createSpotifyPlaylist = async (playlistName, playlistDescription) => {
  try {
    // Validate input
    if (!playlistName || !playlistDescription) {
      return { error: true, message: 'Both playlist name and description are required' };
    }

    const createPlaylistLink = `${process.env.VUE_APP_BACKEND_HOST}:${process.env.VUE_APP_BACKEND_PORT}/createPlaylist`;

    const response = await fetch(createPlaylistLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ playlistName, playlistDescription }),
    });

    if (!response.ok) {
      return { success: false, message: `HTTP error! status: ${response.status}` };
    }

    const data = await response.json();

    console.log("Playlist created successfully:", data.playlistID);

    return { success: true, message: "Playlist created successfully", playlistID: data.playlistID};

  } catch (error) {
    console.error('Error creating Spotify playlist:', error);
    return { success: false, message: error.toString() };
  }
}

export const authoriseSpotifyWithCC = async () => {
  try {
    let spotifyCCAuthLink = `${process.env.VUE_APP_BACKEND_HOST}:${process.env.VUE_APP_BACKEND_PORT}/auth/spotify_client_credentials_authorise`;
    const response = await fetch(spotifyCCAuthLink);
    const data = await response.json(); // Assuming the response is JSON

    console.log("called CC Authorise endpoint. Data received = ", data);
    
    if (data.result == "success") {
      console.log('Spotify token refreshed successfully with CC');
    }
  } catch (error) {
    console.error('Error refreshing Spotify token:', error);
  }
}
export const updateBPMs = async (tracks) => {
  console.log("updating bpm", tracks)
    tracks.forEach(track => {
        updateBPM(track)
    });

    }

    export const reorderByBPM = async (tracks) => {
      console.log("Reordering tracks by BPM", tracks);
      const tracksWithBPM = await Promise.all(tracks.map(async (track) => {
        track.bpm = await updateBPM(track);
        return track;
      }));
      tracksWithBPM.sort((a, b) => a.bpm - b.bpm).forEach((track, index) => {
        track.positionInPlaylist = index + 1;
      });
    }

export const updateBPM = async(track) => {
    if (track.bpm === undefined || track.bpm === null || track.bpm === ""|| track.bpm === 0) {
        console.log(`No BPM for track: ${track.trackName} `);
        console.log(track)
        console.log(track.id)

        fetch(`${process.env.VUE_APP_BACKEND_HOST}:${process.env.VUE_APP_BACKEND_PORT}/getTrackBPM?trackID=${track.id}`)
        .then(response => response.text())
        .then(data => {
          console.log("Got Track BPM: ",  data);
          track.bpm = parseInt(data)  ;
        })
        .catch(error => {
          // Since 'data' is not available in this scope, avoid using it in the error message.
          console.error(error);
        });
    }
    return track.bpm;
}



import axios from 'axios';
axios.defaults.withCredentials = true;

export const checkGoogleAuthStatus = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}:${process.env.VUE_APP_BACKEND_PORT}/api/auth/googlestatus`);
    console.log("Authenticateion response", response.data)
    return response.data; // { isAuthenticated: true/false, user: {} }
  } catch (error) {
    console.error('Error checking authentication status', error);
    return { isAuthenticated: false };
  }
}


export const securePage = async () => {
  const authStatus = await checkGoogleAuthStatus();
  if (authStatus.isAuthenticated) {
    
  } else {
  window.location.href = `${process.env.VUE_APP_BACKEND_HOST}:${process.env.VUE_APP_BACKEND_PORT}/auth/google`;
  }
};

