const contentful = require('contentful');

const client = contentful.createClient({
  space: 'fu11zo1bipqz',
  accessToken: 'ACFlLuRzATEzlNdm1WpYGl8WIsthYCeld69bxlRlhzY'
});

export async function fetchEntries(contentType) {
  const entries = await client.getEntries({
    content_type: contentType
  });
  if (entries.items) return entries.items;
  console.log(`Error getting Entries for ${contentType}.`);
}


export async function fetchEntry(entryId) {
  try {
    const entry = await client.getEntry(entryId);
    return entry;
  } catch (error) {
    console.error("Error fetching entry:", error);
    return null;
  }
}

export default { fetchEntries };