
<template>
  <v-container>
  <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <div v-if="entry" v-html="renderedHtml"></div>
         </v-col>
      </v-row>
  
    </v-container>
  </template>
  
  
  <script>
  import { fetchEntries, fetchEntry } from '@/services/contentfulService';
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
  
  export default {
    data() {
      return {
        entry: null,
        renderedHtml: '',
      };
    },
    async created() {
      this.entry = await fetchEntry('2GxlJtwyHyCj9VDYHU0W0s');
      if (this.entry && this.entry.fields.htmlContent) {
        // Custom rendering options
        const options = {
          renderNode: {
            'heading-1': (node) => {
              const innerHtml = node.content.map(subNode => subNode.value).join('');
              return `<h1 class="v-heading mb-2 text-h3 text-sm-h3">${innerHtml}</h1>`;
              },
            'heading-2': (node) => {
              const innerHtml = node.content.map(subNode => subNode.value).join('');
              return `<h2 class="v-heading mb-2 text-h4 text-sm-h4">${innerHtml}</h1>`;
              },
             },
        };
        this.renderedHtml = documentToHtmlString(this.entry.fields.htmlContent, options);
      }
    },
  };
  </script>